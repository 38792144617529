<template>
	<v-container>
		<v-row>
			<v-col cols="4">
				<v-card>
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-menu
										ref="orderMenu"
										v-model="orderMenu"
										:close-on-content-click="false"
										:return-value.sync="orderMenu"
										transition="scale-transition"
										offset-y
										min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
												v-model="dateOrder"
												label="Дата создания заказа"
												prepend-icon="mdi-calendar"
												readonly
												outlined
												v-bind="attrs"
												v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
											v-model="dateOrder"
											no-title
											scrollable
											range
									>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-menu
										ref="orderMenu"
										v-model="confirmMenu"
										:close-on-content-click="false"
										:return-value.sync="confirmMenu"
										transition="scale-transition"
										offset-y
										min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
												v-model="dateConfirm"
												label="Дата подтверждения заказа"
												prepend-icon="mdi-calendar"
												readonly
												outlined
												v-bind="attrs"
												v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
											v-model="dateConfirm"
											no-title
											scrollable
											range
									>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-select
										v-model="params.statuses"
										:items="statuses"
										multiple
										outlined
										label="Статусы"
										placeholder="Статусы"
								>
								</v-select>
							</v-col>
							<v-col cols="12">
								<v-text-field
										v-model="params.sub"
										outlined
										label="SUB"
										placeholder="SUB"
								>
								</v-text-field>
							</v-col>
							<v-col>
								<v-btn color="green" @click="getInfo()">Подготовить файл</v-btn>
							</v-col>
							<v-col cols="12">
								<v-progress-linear
										v-if="grabInProgress"
										indeterminate
										height="20"
										color="blue"
								></v-progress-linear>
								<a href="https://backend.telestatic.ru/upload/csv/1111.csv" target="_blank"><v-btn v-if="fileReady" color="green">Скачать</v-btn></a>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import httpClient from '../../services/http.service'

	export default {
		name: '1111',
		data(){
			return{
				dateOrder: null,
				dateConfirm: null,
				params: {},
				orderMenu: false,
				confirmMenu: false,
				statuses: ['waiting', 'completed', 'rejected'],
				fileReady: false,
				grabInProgress: false,
			}
		},
		watch:{
			dateOrder(v){
				this.params.dateOrderStart = v[0]
				this.params.dateOrderEnd = v[1]
			},
			dateConfirm(v){
				this.params.dateConfirmStart = v[0]
				this.params.dateConfirmEnd = v[1]
			}
		},
		methods:{
			getInfo(){
				this.fileReady = false
				this.grabInProgress = true
				httpClient.get('reports/eleven?params=' + JSON.stringify(this.params)).then(({data})=>{
					this.grabInProgress = false
					this.fileReady = true
				})
			},
		}
	}
</script>

<style scoped>

</style>